import { proxy, subscribe } from 'valtio';

const setStateToDocument = (state) => {
    const proxyState = proxy(state);
    document.frontEnd = {
        state: proxyState,
        subscribe: (callback) => subscribe(proxyState, callback),
    };
    return proxyState;
};

export default setStateToDocument;