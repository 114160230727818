import {FC} from "react";
import {motion} from "framer-motion";
import {frontEndStore} from "../../store";

const Star:FC<{
    isShow:boolean;
}>=({isShow})=>{
    return <motion.img
        className="transition-opacity object-contain w-12"
        src="./images/desktop/Desktop_Path_Star.png"
        initial={frontEndStore.isMobile?{visibility:'hidden',opacity:1}:{opacity:0}}
        animate={frontEndStore.isMobile?isShow?{
            visibility:'visible',
            scale:[0.4,2,1.6],
        }:{
            visibility:'hidden',
            scale:0
        }:isShow?{
            opacity:1,
            scale:[0.4,2,1.6],
        }:{
            opacity:0,
        }}
        transition={frontEndStore.isMobile?{
            opacity:{duration:0.5},
            scale:{duration:0.5}
        }:{
            opacity:{duration:0.5,type: "tween"},
            scale:{duration:0.5,times:[0,0.5,1]}
        }}

    />
}

export default Star