const introData = {
    zh: {
        content:[
            "過去人們透過網路打破了時空地理的限制，將”展示”從現實帶入虛擬世界；2023年環耀實境透過3D模型結合VR技術，除了展示的資訊、也將”展示的空間”呈現在虛擬網路上。",
            "如現實世界可自由走動探索的空間，再加上特效及背景配樂，讓觀者體驗展覽氛圍；透過小互動的設計，融合展品理念加深觀者印象。虛擬世界擁有無限空間的特性，使得有更多資訊可以陳列其中，不論是產品展示、博物館展覽、文化藝術展覽、房地產等都可供應用。",
            "快點擊下方按鈕來看看，全虛擬的3D展廳，在終點處有意想不到的傳送門唷!",
        ],
        notes:"另外，為了推廣線上藝文展覽，環耀實境製作VirtualMuseum.world網頁，整合全台博物館所擁有的線上環景展覽，方便民眾搜尋參觀，也歡迎多加利用。",
        title:"【虛擬展廳】",
        subtitle2:"【虛擬展廳】",
    },
    en: {
        content:[
            "In the past, people broke the limitations of time and geography through the internet, bringing \"exhibitions\" from reality into the virtual world. In 2023, Panosensing combines 3D modeling with VR technology to not only showcase information, but also present the \"exhibition space\" on the virtual network.",
            "Similar to the freely explorable space in the real world, with special effects and background music, viewers can experience the exhibition atmosphere. Through small interactive designs, the concept of the exhibits is integrated to deepen the viewers' impression. The infinite space characteristic of the virtual world allows for more information to be displayed, which can be applied to product displays, museum exhibitions, cultural and artistic exhibitions, real estate, etc.",
            "Click the button below to take a look at the fully virtual 3D exhibition hall, where there is an unexpected portal waiting for you at the end!",
        ],
        notes:"In addition, in order to promote online art and cultural exhibitions, Panosensing created the VirtualMuseum.world webpage to integrate the online panoramic exhibitions owned by all museums in Taiwan, making it easier for the public to search and visit, and welcome to use it more.",
        title:"【Virtual Exhibition】",
        subtitle2:"Virtual Exhibition",
    },
}

export default introData