import {FC, useEffect, useState} from "react";
import {proxy, useSnapshot} from "valtio";
import infoData from "./infoData";
import {motion,AnimatePresence} from "framer-motion";
import LoadImage from "../ui/LoadImage";
import LoadVideo from "../ui/LoadVideo";
import useShowScroller from "../../hooks/useShowScroller";
import {frontEndStore, windowType} from "../../store";

type DataType = {id:string;
    videoSrc:string;
    imgSrc:string;
    bgImgSrc:string;
    togoSrc:string;
}

const InformationWindow:FC=()=>{
    const {contentId,contentData,lang,buttonSrc} = useSnapshot(frontEndStore.informationState)
    const [showButton,setShowButton]=useState(true)
    const {isButtonOpen,type} = useSnapshot(frontEndStore.helpWindowState)

    useEffect(()=>{
        const newContentData = infoData.data.find((el:DataType)=>el.id === contentId)
        if(newContentData){
            frontEndStore.informationState.contentData = newContentData
        }
    },[contentId])

    const {innerDivRef,outerDivRef,showScroller} = useShowScroller(type)

    return <>
        <motion.div
            className="fixed inset-0 pointer-events-none flex justify-center md:justify-end pointer-events-none"
        >
            {/*背景*/}
            <div
                className={`absolute w-full h-full flex justify-end items-end transition-opacity duration-700`}>
                <motion.img
                    className="h-full absolute object-cover" src="./images/desktop/Desktop_Sidebar_Frame_Down.png"
                    initial={{opacity:0,translateX:300}}
                    animate={isButtonOpen&&type===windowType.info
                        ?{opacity:1,translateX:0}
                        :{opacity:0,translateX:300}
                    }
                    transition={{duration:0.3}}
                />
                <motion.img
                    className="h-1/2 absolute object-cover" src={contentData.bgImgSrc}
                    initial={{opacity:0,translateX:300}}
                    animate={isButtonOpen&&type===windowType.info
                        ?{opacity:1,translateX:0}
                        :{opacity:0,translateX:300}
                    }
                    transition={{duration:0.5}}
                />
                <motion.img
                    className="h-full absolute object-cover" src="./images/desktop/Desktop_Sidebar_Pillar.png"
                    initial={{opacity:0,translateX:300}}
                    animate={isButtonOpen&&type===windowType.info
                        ?{opacity:1,translateX:100}
                        :{opacity:0,translateX:300}
                    }
                    transition={{duration:0.4}}
                />
            </div>

            {/*主內容*/}
            <motion.div
                className={`w-11/12 p-4 md:w-[600px] md:pr-32 md:pt-10 h-full flex flex-col items-start justify-center absolute ${isButtonOpen&&type===windowType.info?'pointer-events-auto':'pointer-events-none'}`}
                initial={{opacity:0}}
                animate={isButtonOpen&&type===windowType.info
                    ?{opacity:1,transition:{delay:0.5,duration:0.4}}
                    :{opacity:0,transition:{duration:0.4}}
                }
            >
                <div className="h-4/12 mt-12 lg:h-5/12 mx-4 border border-2 border-blue-900 shadow-2xl rounded-md">
                    {contentData.imgSrc.length>0&& <LoadImage className="rounded-md" src={contentData.imgSrc}/>}
                    {contentData.videoSrc.length>0&& <LoadVideo className="rounded-md" src={contentData.videoSrc}/>}
                </div>
                <img className="" src="./images/desktop/Desktop_Sidebar_Frame_Sideline_up.png" />
                <div className="flex-1 bg-[#838480] opacity-80 rounded-xl w-full h-40 p-4">
                    <div className={`${showScroller?'overflow-y-scroll':'overflow-hidden'} h-full pr-4`} ref={outerDivRef}>
                        <div className="text-white font-wt leading-7 whitespace-pre-wrap" ref={innerDivRef}>
                            <div>
                                <h6>{contentData.content[lang].title}</h6>
                                <p className="text-lg my-4">{contentData.content[lang].subtitle}</p>
                                <div className="w-full h-1 border-0 border-b-[1px] border-dashed border-white "></div>

                                <p className="my-4">{contentData.content[lang].first}</p>
                                <p className="my-4">{contentData.content[lang].second}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <img className="" src="./images/desktop/Desktop_Sidebar_Frame_Sideline_Down.png" />
                <motion.div className="-mt-6 md:-mt-10 w-full flex justify-center relative group">
                    <img className="object-contain w-[80%] cursor-pointer block group-hover:hidden"
                         onClick={()=>{setShowButton(p=>!p);window.open(contentData.togoSrc, '_blank');}}
                         src={`./images/desktop/${buttonSrc[lang].normal}`} />
                    <img className="object-contain w-[80%] cursor-pointer hidden group-hover:block"
                         onClick={()=>{setShowButton(p=>!p);window.open(contentData.togoSrc, '_blank');}}
                         src={`./images/desktop/${buttonSrc[lang].hover}`} />
                </motion.div>

            </motion.div>

        </motion.div>
    </>
}

export default InformationWindow