import {FC, useEffect, useRef, useState} from "react";
import {motion, useMotionValue} from "framer-motion";
import {useSnapshot} from "valtio";
import useWindowSize from "../../hooks/useWindowSize";
import {carouselState} from "./PlanetCarousel";

const getScaleValue=(angle:number,maxRate:number,minRate:number)=>{
    if(angle>0){
        if(angle===90){
            return maxRate
        }else if((angle>90&&angle<=135)||(angle<90&&angle>=45)){
            return maxRate-(maxRate-1)*Math.abs(angle-90)/45
        }else{
            return 1-(1-minRate)*Math.abs(angle-90)/45
        }
    }else{
        const absAngle = Math.abs(angle)
        if(absAngle===90){
            return maxRate
        }else if((absAngle>90&&absAngle<=135)||(absAngle<90&&absAngle>=45)){
            return maxRate-(maxRate-1)*Math.abs(absAngle-90)/45
        }else{
            return 1-(1-minRate)*Math.abs(absAngle-90)/45
        }
    }
}

const getPointOnEllipse = (a:number, b:number, angleInDegrees:number) => {
    const angleInRadians = angleInDegrees * Math.PI / 180
    const x = a * Math.cos(angleInRadians)
    const y = b * Math.sin(angleInRadians)
    return { x, y }
}

const getTransValue=(angle:number,containerLength:number,length:number)=>{
    const movableLength = (containerLength-length)
    const {x,y} = getPointOnEllipse(containerLength/4,length/2,angle)
    return -x+movableLength/2
}

const getOpacityValue=(angle:number)=>{
    if(angle>0){
        return (angle%360<180 && angle%360>0)?1:0
    }else{
        return (Math.abs(angle%360)<180 && Math.abs(angle%360)>0)?0:1
    }
}

const Planet:FC<{
    offset:number;
    className?:string;
    imgSrc?:string;
    name:string;
    url:string;
}>=({offset=0,className,imgSrc,name,url})=>{
    const {currentRotation,containerWidth,openPlanet,selectedPlanet,
        ballLength, maxRate, minRate, ballContainerLength,carouselBottomOffset,canClick} = useSnapshot(carouselState)
    const offsetRotate = currentRotation+offset
    const divRef = useRef<HTMLImageElement>(null);

    const [initial,setInitial]=useState(true)

    useEffect(()=>{
        setInitial(false)
    },[])
    let timer = setTimeout(()=>{})

    return <motion.div
        className="absolute rounded-full border border-0 border-rose-100 flex items-center pointer-events-none"
        style={{width:ballContainerLength,height:ballContainerLength}}
        initial={{rotateZ:offsetRotate}}
        animate={{
            rotateZ:offsetRotate,
            translateX:getTransValue(offsetRotate,containerWidth,ballContainerLength)
        }}
    >
        <motion.div
            className={`${canClick&&selectedPlanet===name?'cursor-pointer pointer-events-auto':''} rounded-full origin-left ${!imgSrc&&(className?className:'bg-rose-300')}`}
            style={{
                width:ballLength,
                height:ballLength,
            }}
            initial={{scale:1}}
            animate={{
                scale:getScaleValue(offsetRotate%180,maxRate,minRate),
                opacity:getOpacityValue(offsetRotate),
                translateX:-1*ballLength/2*getScaleValue(offsetRotate%180,maxRate,minRate),
                // translateY:offsetRotate%90/22.5, // 越靠近 45,135,225,315 偏移量就越大，越靠近 0,90,180,270 偏移量趨近於0
            }}
            onClick={()=>{
                carouselState.openPlanet=name
                clearTimeout(timer)
                timer = setTimeout(() => {
                    window.open(url, '_blank');
                }, 2500);
            }}
        >
            {imgSrc&&!(openPlanet===name)&&<motion.img
                ref={divRef}
                className={`absolute w-full h-full pointer-events-none drop-shadow-[0_0_6px_rgba(0,0,0,0.8)] select-none ${selectedPlanet===name?'grayscale-0':'grayscale'}`}
                src={imgSrc}
                style={{scale:1,rotateZ:-90}}
                initial={{opacity:0}}
                animate={{opacity:1}}
                transition={initial
                    ?{opacity:{delay:0},default:{duration:0.5}}
                    :{opacity:{delay:0,duration:0},default:{duration:0.5}}}
            />}
        </motion.div>
    </motion.div>
}
export default Planet