import {FC, MouseEvent , useCallback, useEffect, useRef, useState} from "react";
import {motion} from "framer-motion";
import {proxy, useSnapshot} from "valtio";
import Planet from "./Planet";
import useWindowSize from "../../hooks/useWindowSize";
import {frontEndStore} from "../../store";
import {windowSizeState} from "../../util/windowSize";

export const carouselState = proxy({
        isShow:false,
        currentRotation: 0,
        containerHeight: 200,
        containerWidth: 600,
        mobileScaleWidth:1024,
        gapAngle:-40,
        startAngle:90,
        selectedPlanet:'Mercury',
        openPlanet:'',
        currentIndex:0,
        canClick:false,
        get carouselBottomOffset(){ // 整體向下移動多少
            const addHeightOffset = (window.innerHeight/3 - (this.containerHeight*0.4+this.containerHeight/20))
            if(addHeightOffset>0){
                return -this.containerHeight*0.7
            }else{
                return -this.containerHeight*0.7 + addHeightOffset
            }
        },
        get ballLength(){
            if((this.containerHeight/10)>55){
                return 55
            }else if((this.containerHeight/10)<40){
                return 40
            }else{
                return this.containerHeight/10
            }
        },
        get ballContainerLength(){ return this.containerHeight },
        maxRate:2,
        minRate:1.2,
    })

const planetList = [
    {imgSrc:'./images/desktop/planet/Desktop_Icon_Mercury.png',name:'Mercury',id:'05',bgColor:'#aca0aa',url:'https://exhibition.nstm.gov.tw/opensesame/vrtour/',content:'科學開門'},
    {imgSrc:'./images/desktop/planet/Desktop_Icon_Venus.png',name:'Venus',id:'06',bgColor:'#ffdbaa',url:'https://vr360.nmh.gov.tw/beautyofthesea/',content:'海之美'},
    {imgSrc:'./images/desktop/planet/Desktop_Icon_Earth.png',name:'Earth',id:'01',bgColor:'#3ab4bd',url:'https://vr.ntm.gov.tw/2022Exploring/',content:'台灣博物館'},
    {imgSrc:'./images/desktop/planet/Desktop_Icon_Mars.png',name:'Mars',id:'02',bgColor:'#f15d28',url:'https://www.npac-ntt.org/360virtualtour/',content:'台中國家歌劇院'},
    {imgSrc:'./images/desktop/planet/Desktop_Icon_Jupiter.png',name:'Jupiter',id:'03',bgColor:'#f9c87c',url:'https://www.virtualmuseum.world/suhopaper/',content:'樹火玩紙博物館'},
    {imgSrc:'./images/desktop/planet/Desktop_Icon_Saturn.png',name:'Saturn',id:'07',bgColor:'#fec96d',url:'https://temp.panosensing.com/aver/beta/v0/vr/v0.html',content:'圓展'},
    {imgSrc:'./images/desktop/planet/Desktop_Icon_Uranus.png',name:'Uranus',id:'08',bgColor:'#88d5e0',url:'https://www.smartcitytw360.com/',content:'智慧城鄉'},
    {imgSrc:'./images/desktop/planet/Desktop_Icon_Neptune.png',name:'Neptune',id:'09',bgColor:'#668acf',url:'https://temp.panosensing.com/nexcom/beta_v1_1/vr/',content:'Nexcom'},
    {imgSrc:'./images/desktop/planet/Desktop_Icon_Pluto.png',name:'Pluto',id:'04',bgColor:'#e5cfb3',url:'https://vr.ntm.gov.tw/2022Seed/',content:'孩籽'},
]

export function useCycle() {
    const {currentIndex,selectedPlanet,openPlanet} = useSnapshot(carouselState)

    function next() {
        carouselState.currentIndex = (currentIndex + 1) % planetList.length
        carouselState.selectedPlanet = planetList[carouselState.currentIndex].name
    }

    function prev() {
        carouselState.currentIndex = (currentIndex + planetList.length - 1) % planetList.length;
        carouselState.selectedPlanet = planetList[carouselState.currentIndex].name
    }

    return {
        next,
        prev,
    };
}

const PlanetCarousel:FC=()=>{
    const {selectedPlanet, currentRotation,containerHeight,containerWidth,mobileScaleWidth,canClick,
        startAngle,gapAngle,openPlanet,ballLength, maxRate,currentIndex,carouselBottomOffset} = useSnapshot(carouselState)

    const [canDrag,setCanDrag]=useState(false)
    const [planetBg,setPlanetBg]=useState('')

    const {lg,xl} = useSnapshot(windowSizeState)
    const {width,height} = useWindowSize()
    const { next, prev} = useCycle()


    const [isGo,setIsGo] = useState(false)

    useEffect(()=>{
        let timer= setTimeout(()=>{})

        if(isGo){
            timer = setTimeout(() => {
                window.open("https://www.panosensing.com.tw/", '_blank');
                setIsGo(false)
            }, 350);
        }

        return ()=>{clearTimeout(timer)}
    },[isGo])

    useEffect(()=>{
        let nameTimer = setTimeout(()=>{})
        let clickTimer = setTimeout(()=>{})
        if(openPlanet.length>0){
            carouselState.canClick = false
            nameTimer = setTimeout(()=>{
                carouselState.openPlanet = ''
            },5000)
        }else{
            clickTimer = setTimeout(()=>{
                carouselState.canClick = true
            },3000)
        }
        return ()=>{
            clearTimeout(nameTimer)
            clearTimeout(clickTimer)
        }
    },[openPlanet])

    useEffect(()=>{
        // 總螢幕寬度大於mobileScaleWidth的話，就用總寬度去算
        const calculateHeight = width>mobileScaleWidth?width*0.5*0.8:mobileScaleWidth*0.8*0.5
        const calculateWidth = width>mobileScaleWidth?width*0.4:mobileScaleWidth*0.8*0.5
        carouselState.containerHeight = calculateHeight
        carouselState.containerWidth = calculateWidth
    },[width,height])

    useEffect(()=>{
        const newPlanet = planetList.find(el=>el.name===selectedPlanet)
        if(newPlanet){
            setPlanetBg(newPlanet.bgColor)
        }
    },[selectedPlanet])

    const [lastMouseDownX, setLastMouseDownX] = useState(0);

    const handleMouseDown = useCallback((e: MouseEvent<HTMLDivElement>) => {
        setCanDrag(true)
        setLastMouseDownX(e.clientX);
    }, [canDrag, carouselState, lastMouseDownX]);

    const handleMouseMove = useCallback((e: MouseEvent<HTMLDivElement>) => {
        if (canDrag) {
            const movementX = e.clientX - lastMouseDownX;
            if (Math.abs(movementX) > 10) {
                carouselState.currentRotation += (movementX/20);
            }
        }
    }, [canDrag, carouselState, lastMouseDownX]);

    const handleMouseUp = useCallback(()=>{
        setCanDrag(false)
        const snapValue = Math.trunc(currentRotation/gapAngle)
        const snapOffset = Math.round(currentRotation%gapAngle)
        if(Math.abs(snapOffset)>gapAngle/2){
            if(snapOffset>=0){
                carouselState.currentRotation = (snapValue+1)*gapAngle
            }else{
                carouselState.currentRotation = (snapValue-1)*gapAngle
            }
        }else{
            carouselState.currentRotation = snapValue*gapAngle
        }
    }, [gapAngle])


    return <motion.div
        className="w-screen fixed bottom-0 left-0 flex flex-col items-center justify-center"
        style={{
            height:containerHeight,
            bottom:width>mobileScaleWidth?carouselBottomOffset:carouselBottomOffset+50,
    }}
    >
        {/*軌道*/}
        <motion.div
            className="absolute border border-4 border-white flex items-start justify-center"
            initial={{opacity:0}}
            animate={{opacity:1,transition:{delay:0}}}
            style={{
                borderRadius:"50%",
                width:width>mobileScaleWidth?containerWidth*1.5:containerWidth*1.5,
                height:containerHeight,
                padding:ballLength
            }}
        >
            {/*控制箭頭*/}
            <motion.div className={`fixed flex justify-center items-center`}
                        style={{marginTop:-ballLength*maxRate,height:ballLength*maxRate}}
                        initial={{opacity:0}}
                        animate={{opacity:1}}
                        transition={{opacity:{delay:0}}}
            >
                <motion.div
                    className={`w-16 lg:w-20 drop-shadow-[0_0_6px_rgba(0,0,0,0.8)] ${canClick?'cursor-pointer pointer-events-auto':''} group text-white transition-colors hover:text-[#b9b4ab]`}
                    onClick={()=>{
                        carouselState.currentRotation = currentRotation+40
                        next()
                        carouselState.openPlanet = ''
                    }}
                    style={{marginRight:containerHeight*0.15}}
                >
                    <Arrow/>
                </motion.div>
                <motion.div
                    className={`w-16 lg:w-20 drop-shadow-[0_0_6px_rgba(0,0,0,0.8)] ${canClick?'cursor-pointer pointer-events-auto':''} group text-white transition-colors hover:text-[#b9b4ab] rotate-180`}
                    onClick={()=>{
                        carouselState.currentRotation = currentRotation-40
                        prev()
                        carouselState.openPlanet = ''
                    }}
                    style={{marginLeft:containerHeight*0.15}}
                >
                    <Arrow/>
                </motion.div>
            </motion.div>
            <motion.div className="w-full h-full rounded-[50%] relative bg-opacity-0 flex flex-col items-center select-none"
                        style={{boxShadow:'rgba(255,255,255,0.3) 0 0 25px 25px inset'}}
            >
                <motion.div
                    className="flex flex-col justify-center items-center"
                    style={{width:xl
                            ?ballLength*maxRate*4
                            :lg?ballLength*maxRate*3:ballLength*maxRate*5}}
                >
                    <motion.div className="absolute text-white font-wt text-center mt-4 lg:mt-2 text-3xl lg:text-2xl"
                                style={{width:ballLength*maxRate*3}}
                    >{planetList[currentIndex].content}</motion.div>
                    <img src="./images/desktop/Desktop_Sidebar_Frame_Sideline_up.png"/>
                    <img src="./images/desktop/Desktop_Sidebar_Frame_Sideline_Down.png"/>
                </motion.div>
            </motion.div>

        </motion.div>

        {/*星球*/}
        <motion.div className="border border-0 relative"
                    style={{
                        width:containerWidth,
                        height:containerHeight,
                    }}>
            {planetList.map((el,i)=>{
                return <Planet key={el.imgSrc} name={el.name} url={el.url} imgSrc={el.imgSrc} offset={startAngle+i*gapAngle}/>
            })}
        </motion.div>



        {/*點擊後往上飛的小球*/}
        <motion.div className="fixed inset-0 top-0 left-0 flex justify-center items-center rotate-180">
            <motion.div className="absolute flex justify-center items-center">
                <motion.div className="absolute z-10"
                            style={{backgroundColor:planetBg,borderRadius:'50%'}}
                            initial={{opacity:0,width:'100%',height:'100%'}}
                            animate={openPlanet.length>0?{
                                opacity:1,width:'150vw',height:'150vh',transition:{opacity:{delay: 1.5, duration: 0},default: {delay: 1.5, duration: 1}}
                            }:{
                                opacity:0,width:'100%',height:'100%',transition:{opacity:{delay:1,duration: 0},default:{duration:1}}
                            }}
                />
            {planetList.map(el=><motion.img
                key={`layout${el.name}`}
                className="last:relative absolute select-none z-10"
                src={el.imgSrc}
                style={{
                    scale:1, width:ballLength*maxRate,height:ballLength*maxRate,rotateZ:180
                }}
                initial={{opacity:0,
                    translateY:-(height/2-containerHeight-carouselBottomOffset)
            }}
                animate={(selectedPlanet===el.name)&&(openPlanet===el.name)?{
                    opacity:1,translateY:0,transition:{opacity:{duration:0},default:{duration:1}}
                }:{
                    opacity:0,
                    translateY:-(height/2-containerHeight-carouselBottomOffset)
                    ,transition:{opacity:{delay:2,duration:0},default:{duration:1,delay:1}}
                }}
            />)}
            </motion.div>
        </motion.div>

        {/*Drag層*/}
        {/*<div*/}
        {/*    className="absolute bottom-0 left-0 w-screen h-full pointer-events-auto"*/}
        {/*    onMouseDown={handleMouseDown}*/}
        {/*    onMouseUp={handleMouseUp}*/}
        {/*    onMouseLeave={()=>{setCanDrag(false)}}*/}
        {/*    onMouseMove={handleMouseMove}*/}
        {/*></div>*/}

    </motion.div>
}

export default PlanetCarousel


const Arrow = () => {
    return <svg className="drop-shadow-xl" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                viewBox="0 0 121.6 121.1">
        <polygon stroke="none" fill="currentColor"
                 points="121.5,94 121.5,80.6 86.6,60.4 121.5,40.3 121.5,26.8 63.3,60.4"/>
        <polygon fill="currentColor"
                 points="69.8,20.1 0,60.4 69.8,100.7 104.7,120.8 104.7,94 46.4,60.4 104.7,26.8 104.7,0"/>
    </svg>
}