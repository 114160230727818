import React, {FC} from "react";
import {motion} from "framer-motion";
import {useSnapshot} from "valtio";
import {windowSizeState} from "../util/windowSize";

const Macrame:FC=()=>{
    const {lg,md} = useSnapshot(windowSizeState)
    return <>
        <motion.img
            className="w-64 object-contain pointer-events-none select-none fixed"
            src="./images/desktop/Desktop_Frame_Corner.png"
            style={lg?{rotateX:180,scale:1,top:-16,left:-48}
                :md?{rotateX:180,scale:0.8,top:-28,left:-60}
                    :{rotateX:180,scale:0.6,top:-48,left:-76}}/>
        <motion.img
            className="w-64 object-contain pointer-events-none select-none fixed"
            src="./images/desktop/Desktop_Frame_Corner.png"
            style={lg?{rotateX:180,rotateY:180,scale:1,top:-16,right:-48}
                :md?{rotateX:180,rotateY:180,scale:0.8,top:-28,right:-60}
                    :{rotateX:180,rotateY:180,scale:0.6,top:-48,right:-76}}/>
        <motion.img
            className="w-64 object-contain pointer-events-none select-none fixed"
            src="./images/desktop/Desktop_Frame_Corner.png"
            style={lg?{scale:1,bottom:-16,left:-48}
                :md?{scale:0.8,bottom:-28,left:-60}
                    :{scale:0.6,bottom:-48,left:-76}}/>
        <motion.img
            className="w-64 object-contain pointer-events-none select-none fixed"
            src="./images/desktop/Desktop_Frame_Corner.png"
            style={lg?{rotateY:180,scale:1,bottom:-16,right:-48}
                :md?{rotateY:180,scale:0.8,bottom:-28,right:-60}
                    :{rotateY:180,scale:0.6,bottom:-48,right:-76}}/>
    </>
}
export default Macrame