import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { proxy, useSnapshot } from 'valtio';
import Spinner from "./Spinner";

interface VideoProps {
    src: string;
    muted?: boolean;
    playsInline?: boolean;
    autoPlay?: boolean;
    loop?: boolean;
    className?:string;
}

const state = proxy({ isLoading: true });

const LoadVideo = ({ src, loop=true,muted = true, playsInline = true, autoPlay = true, className }: VideoProps) => {
    const [videoLoaded, setVideoLoaded] = useState(false);
    const { isLoading } = useSnapshot(state);

    const handleVideoLoad = () => {
        state.isLoading = false;
        setVideoLoaded(true);
    };

    return (
        <div className="relative w-full h-full">
            {isLoading && <Spinner/>}
            <video
                className={`${isLoading?'opacity-0':''} ${!videoLoaded?'hidden':''} ${className?className:''}`}
                muted={muted}
                playsInline={playsInline}
                autoPlay={autoPlay}
                onLoadedData={handleVideoLoad}
                loop={loop}
            >
                <source src={src} type="video/mp4" />
            </video>
        </div>
    );
};

export default LoadVideo;