import React, {FC, ReactNode} from 'react';
import {proxy, useSnapshot} from 'valtio'
import {motion,AnimatePresence} from "framer-motion";

import MiniMap from "./components/MiniMap/MiniMap";
import PlanetCarousel from "./components/PlanetCarousel/PlanetCarousel";
import Macrame from "./components/Macrame";
import HelpWindow from "./components/HelpWindow";
import Circles from "./components/beautify/Circles";
import SmallCircles from "./components/beautify/SmallCircles";
import {frontEndStore} from "./store";
import Fixed from "./components/ui/Fixed";
import Intro from "./components/Intro/Intro";

declare module 'valtio' {
    function useSnapshot<T extends object>(p: T): T
}

function App() {
    const snap = useSnapshot(frontEndStore)

    return (
        <div className="fixed top-0 left-0 w-screen h-screen pointer-events-none">

            {/*{snap.lockControl&&<div className="fixed w-screen h-screen top-0 left-0 pointer-events-auto"/>}*/}

            <FadeDiv isShow={snap.isShow}>
                {/*裝飾*/}
                <FadeDiv isShow={snap.macrameState.isShow}>
                    <Macrame/>
                </FadeDiv>
                <FadeDiv isShow={snap.circleState.isShow}>
                    <Circles/>
                    <SmallCircles/>
                </FadeDiv>

                {/*功能*/}
                <FadeDiv isShow={snap.miniMapState.isShow}><MiniMap/></FadeDiv>
                <FadeDiv isShow={snap.helpWindowState.isShow}><HelpWindow/></FadeDiv>
                <FadeDiv isShow={snap.carouselState.isShow}><PlanetCarousel/></FadeDiv>
            </FadeDiv>


            <Intro/>

            {/*<Circles/>*/}
            {/*<SmallCircles/>*/}
            {/*<Macrame/>*/}
            {/*<MiniMap/>*/}
            {/*<HelpWindow/>*/}
            {/*<PlanetCarousel/>*/}

        </div>
    );
}

export default App;

const fadeVariant = {
    initial:{opacity:0},
    animate:{opacity:1},
    exit:{opacity:0},
}
const FadeDiv:FC<{children:ReactNode;isShow:boolean}>=({children,isShow})=>{
    return <AnimatePresence>
        {isShow&&<motion.div initial={{opacity:0}}
            animate={{opacity:1}}
                             exit={{opacity:0}}
            >
            {children}
        </motion.div>}
    </AnimatePresence>
}