const infoData = {
    buttonSrc:{
      zh:{
          normal:'Desktop_GoToP_HvBtn.png',
          hover:'Desktop_GoToP_Btn.png'
      },
      en:{
          normal:'Desktop_GoToP_HvBtn_en.png',
          hover:'Desktop_GoToP_Btn_en.png'
      },
    },
    data:[
        {
            id:'content01',
            videoSrc:'',
            imgSrc:'./images/desktop/paint/end_animation-01.png',
            bgImgSrc:'./images/desktop/Desktop_Sidebar_Frame_Lv01.png',
            togoSrc:'https://vr.ntm.gov.tw/2022Seed/',
            content:{
                zh:{
                    title:'[讓插畫動起來]',
                    subtitle:'展覽；孩籽–聽種子的故事\n主辦單位；國立臺灣博物館',
                    first:'《孩籽‧聽種子的故事》以種子的生活應用為始，隨後展開種子植物演化史，以及由種子、果實等共譜的生態故事，從自爆、偷渡到乘風破浪，千變萬化！最後結尾於一個人類必須正視的話題：保種，倡議孩子與孩籽攜手未來的實踐。',
                    second:'在展覽尾聲，我們為孩籽繪製一幅畫並製作成一段動畫，以種子為發想帶出種子與生命間的循環，快前往展覽網站看看吧!',
                },
                en:{
                    title:'[Let illustrations come to life]',
                    subtitle:'Exhibition: Seeds of Life - Listen to the Stories of Seeds\nOrganizer: National Taiwan Museum',
                    first:'"Seeds of Life - Listen to the Stories of Seeds" starts with the practical uses of seeds and then unfolds the evolution history of seed plants, as well as the ecological stories composed of seeds, fruits, and more, featuring a variety of transformations from self-destruction and smuggling to braving the wind and waves. The exhibition ends with a topic that humans must confront: seed preservation, advocating for children and seeds to work together towards a sustainable future.',
                    second:'At the end of the exhibition, we have created a drawing and turned it into an animation for the seeds, using seeds as the inspiration to showcase the cycle between seeds and life. Check out the exhibition website for more details!',
                }
            }
        },{
            id:'content02',
            videoSrc:'./images/desktop/paint/ending_animation_part1.mp4',
            imgSrc:'',
            bgImgSrc:'./images/desktop/Desktop_Sidebar_Frame_Lv02.png',
            togoSrc:'https://vr360.nmh.gov.tw/beautyofthesea/',
            content:{
                zh:{
                    title:'[互動增加記憶點]',
                    subtitle:'展覽；海之美–海洋文化與臺灣風貌\n主辦單位；國立歷史博物館、新北市立十三行博物館',
                    first:'理解、剖析、突破、轉化、再傳達!\n這是我們製作展覽時的思路：通過理解展覽理念與意涵、思考其核心、依核心為出發點，再突破線上形式的限制後，將之轉化成新的互動。觀者通過互動，在遊戲間體悟出展覽欲傳達之理念是我們執行的目標；也通過這些小小的互動，讓觀者對於展覽有更深的印象。',
                    second:'在展覽結尾，我們嘗試加入互動，得到有趣的反饋。在這之後也越加突破自我，開始在其他展覽裡增加遊戲，最後甚至有跟臺博館《博物台灣》合作，製作出線上解謎遊戲呢!',
                },
                en:{
                    title:'[Increasing Memory Points through Interaction]',
                    subtitle:'Exhibition: Beauty of the Sea – Ocean Culture and Taiwan\'s Landscape\nOrganizers: National Museum of History, New Taipei City Shi San Hang Museum',
                    first:'Understand, analyze, breakthrough, transform, and convey!\nThis was our thought process when creating the exhibition: by understanding the exhibition\'s concept and meaning, thinking about its core, starting from the core, and then breaking through the limitations of online formats, we transformed it into new interactions. Through these interactions, viewers can experience the exhibition\'s ideas through games, and we aim to leave a deeper impression on the viewers.',
                    second:'At the end of the exhibition, we tried to add interactions and received interesting feedback. After that, we continued to push our limits and began to add games to other exhibitions. Finally, we even collaborated with the National Museum of Taiwan in creating an online puzzle game!',
                }
            }
        }
    ]
}

export default infoData