import {useEffect, useRef, useState} from "react";
import { useSnapshot, proxy } from "valtio";

const state = proxy({
    showScroller: false
});

const useShowScroller=(dep)=> {
    const innerDivRef = useRef(null);
    const outerDivRef = useRef(null);
    const {showScroller} = useSnapshot(state);

    useEffect(() => {
        const handleResize = () => {
            if (
                innerDivRef.current &&
                outerDivRef.current &&
                innerDivRef.current.offsetHeight > outerDivRef.current.offsetHeight
            ) {
                state.showScroller = true;
            } else {
                state.showScroller = false;
            }
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [dep]);

    return {innerDivRef,outerDivRef,showScroller}
}

export default useShowScroller