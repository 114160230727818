import {motion} from "framer-motion";
import {FC} from "react";
import Star from "./Star";
import {useSnapshot} from "valtio";
import {windowSizeState} from "../../util/windowSize";

const SmallCircles:FC=()=>{
    const {lg,md} = useSnapshot(windowSizeState)
    return <motion.div
        className="origin-right"
        style={lg ?{scale:1} :md ?{scale:0.8} :{scale:0.6}}
    >
        <motion.div className="fixed right-0 bottom-0 pointer-events-none"
                    style={{scale:1,translateX:'-9px',translateY:'-351px'}}
        >
            <Star/>
        </motion.div>
        <motion.div className="fixed right-0 bottom-0 pointer-events-none"
                    style={{scale:0.6,translateX:'-59px',translateY:'-601px'}}
        >
            <Star/>
        </motion.div>

    <motion.div className="flex items-center h-screen pointer-events-none"
                initial={{opacity:0}}
                animate={{opacity:1,transition:{delay:0}}}
    >
        <motion.div
            className="absolute w-[800px] h-[800px] right-0 flex justify-center items-center"
            style={{translateX:567,translateY:85,rotateZ:95,scale:0.8}}
        >
            <motion.img className="absolute  select-none pointer-events-none" src="./images/desktop/circle/Desktop_Hall_PlanetRing_01.png"
                        initial={{rotateZ:0}}
                        animate={{rotateZ:-360}}
                        transition={{duration:40,repeat:Infinity}}
                        style={{translateX:82,translateY:154,width:'60%',height:'60%'}}
            />
            <motion.img className="absolute w-5/6 h-5/6  select-none pointer-events-none" src="./images/desktop/circle/Desktop_Hall_PlanetRing_02.png"
                        initial={{rotateZ:0}}
                        animate={{rotateZ:360}}
                        transition={{duration:30,repeat:Infinity}}
                        style={{translateX:35,translateY:70}}
            />
            <motion.img className="absolute w-full h-full  select-none pointer-events-none" src="./images/desktop/circle/Desktop_Hall_PlanetRing_03.png"
                        initial={{rotateZ:0}}
                        animate={{rotateZ:-360}}
                        transition={{duration:50,repeat:Infinity}}
                        style={{translateX:0,translateY:0}}
            />
        </motion.div>
    </motion.div>
    </motion.div>
}

export default SmallCircles