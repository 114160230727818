import {AnimatePresence, motion, motionValue} from "framer-motion";
import {frontEndStore} from "../../store";
import React, {useEffect, useRef, useState} from "react";
import {proxy, useSnapshot} from "valtio";
import useCountBackwards from "../../hooks/useCountBackwards";
import {windowSizeState} from "../../util/windowSize";
import useShowScroller from "../../hooks/useShowScroller";


const getRandomNumbers = () => {
    const numbers: number[] = [];
    while (numbers.length < 8) {
        const randomNumber = Math.floor(Math.random() * 99);
        if (!numbers.includes(randomNumber)) {
            numbers.push(randomNumber);
        }
    }
    return numbers.sort();
};

const Intro=()=>{
    const {isShow,isShowButton,data} =useSnapshot(frontEndStore.intro)
    const {lang} =useSnapshot(frontEndStore.informationState)

    return <AnimatePresence>
        {isShow && <motion.div
            className="fixed w-screen h-screen top-0 left-0 pointer-events-auto flex flex-col justify-center items-center"
            initial={{opacity:1}}
            exit={{opacity:0}}
        >
            <div className="fixed top-8 left-8 w-40"><img src="./images/desktop/Desktop_Logo_AllW.png"/></div>
            <div className="fixed right-7 top-8 flex justify-center items-center">

                <img className={`pointer-events-none absolute z-30 w-8 ${lang==='zh'?'block':'hidden'}`} src="./images/desktop/Desktop_En_Btn.png"/>
                <img className={`pointer-events-none absolute z-30 w-8 ${lang==='en'?'block':'hidden'}`} src="./images/desktop/Desktop_Ch_Btn.png"/>
                <div
                    className="group z-20 w-16 h-16 p-[2px] rotate-45 cursor-pointer rounded-md bg-[#838480] hover:bg-[#b9b4ab] transition-all border border-white border-[5px] shadow-xl"
                    onClick={()=>{
                        if(lang==='zh'){
                            frontEndStore.informationState.lang = 'en'
                        }else{
                            frontEndStore.informationState.lang = 'zh'
                        }
                    }}
                >
                    <div className="w-full h-full cursor-pointer rounded-md bg-[#838480] group-hover:bg-[#b9b4ab] transition-all border border-white border-[2px]"/>
                </div>
            </div>


            {/*背景底圖*/}
            <img className={`object-cover absolute z-[-1] h-full`} src="./images/desktop/Desktop_Start_BG_DK.png"/>

            {/*主要內容*/}
            <div className="scale-90 w-full flex flex-col justify-center items-center gap-8 text-white font-wt">
                {/*上花邊*/}
                <img className="w-11/12 md:w-2/3 md:min-w-[500px]" src="./images/desktop/Desktop_Start_Title_Frame_Up.png"/>

                <div className="text-center hidden md:block md:text-5xl">{data[lang].title}</div>
                <div className="text-center text-6xl block md:hidden">{data[lang].subtitle2}</div>


                <div className="relative rounded-xl w-11/12 md:w-1/2 md:min-w-[480px] h-1/2 py-20 pl-20 pr-12 -mt-6 -mb-6">
                    {/*花邊部分*/}
                    <div className="absolute inset-0 flex flex-col justify-center items-center">
                        <div className="flex w-full">
                            <div className="h-20"><motion.img className="h-full object-contain" style={{rotateY:180}} src="./images/frame/Desktop_page-91.png" alt=""/></div>
                            <div className="h-20 flex-1"><motion.img className="h-full w-full" src="./images/frame/Desktop_page-92.png" alt=""/></div>
                            <div className="h-20"><motion.img className="h-full object-contain" style={{rotateY:0}} src="./images/frame/Desktop_page-91.png" alt=""/></div>
                        </div>
                        <div className="flex w-full flex-1">
                            <motion.div className="w-20 h-full flex"><motion.img className="h-full" style={{rotateY:180}} src="./images/frame/Desktop_page-93.png" alt=""/></motion.div>
                            <div className="h-full flex-1"></div>
                            <motion.div className="w-20 h-full flex"><motion.img className="h-full" style={{rotateY:0}} src="./images/frame/Desktop_page-93.png" alt=""/></motion.div>
                        </div>
                        <div className="flex w-full">
                            <div className="h-20"><motion.img className="h-full object-contain" style={{rotateX:180,rotateY:180}} src="./images/frame/Desktop_page-91.png" alt=""/></div>
                            <div className="h-20 flex-1"><motion.img className="h-full w-full" style={{rotateX:180}} src="./images/frame/Desktop_page-92.png" alt=""/></div>
                            <div className="h-20"><motion.img className="h-full object-contain" style={{rotateX:180,rotateY:0}} src="./images/frame/Desktop_page-91.png" alt=""/></div>
                        </div>
                    </div>
                    <div className={`relative overflow-y-scroll h-full pr-6`} >
                        <div className="leading-7 whitespace-pre-wrap" >
                            <div className="flex flex-col justify-center gap-4">
                                {data[lang].content.map((el:any)=><p key={Math.random()}>{el}</p>)}
                            </div>
                            <div className="mt-4">
                                <p className="text-sm">{data[lang].notes}</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/*下花邊*/}
                <img className="w-11/12 md:w-2/3 md:min-w-[500px]" src="./images/desktop/Desktop_Start_Title_Frame_Down.png"/>

                {/*按鈕*/}
                <div className="flex flex-col justify-center items-center" >
                    {/*progress*/}
                    <div className="absolute w-80 flex flex-col justify-center items-center pointer-events-none">
                        <motion.div className={`text-white font-wt ${isShowButton?'':'animate-pulse'}`}
                                    initial={{opacity: 1}}
                                    animate={isShowButton ? {opacity: 0} : {}}
                                    transition={{delay: 2}}
                        >Loading...</motion.div>

                        <motion.div className="w-full bg-gray-400 rounded-full"
                                    initial={{opacity: 1}}
                                    animate={isShowButton ? {opacity: 0} : {}}
                                    transition={{delay: 2}}
                        >
                            <motion.div
                                className="bg-white text-xs font-medium text-white text-center p-0.5 leading-none rounded-full"
                                initial={{width: 0}}
                                animate={!isShowButton ? {width: '98%', transition: {duration: 8}} : {
                                    width: '100%',
                                    transition: {duration: 0.5}
                                }}
                            >
                            </motion.div>
                        </motion.div>
                    </div>

                    {/*按鈕部分*/}
                    <motion.div
                        className="flex flex-col justify-center items-center"
                        initial={{opacity: 0}}
                        animate={isShowButton ? {opacity: 1} : {opacity: 0}}
                        transition={{delay: 2}}
                    >

                        <motion.div
                            className={`group w-64 ${isShowButton ? 'cursor-pointer pointer-events-auto' : 'pointer-events-none'}`}
                            onClick={() => {
                                frontEndStore.intro.isShow = false
                            }}
                        >
                            {lang==='zh'&&<>
                                <img className="group-hover:block hidden" src="./images/desktop/Desktop_Start_HvBtn.png"/>
                                <img className="group-hover:hidden block" src="./images/desktop/Desktop_Start_Btn.png"/>
                            </>}

                            {lang==='en'&&<>
                                <img className="group-hover:block hidden" src="./images/desktop/Desktop_Start_HvBtn_en.png"/>
                                <img className="group-hover:hidden block" src="./images/desktop/Desktop_Start_Btn_en.png"/>
                            </>}
                        </motion.div>
                    </motion.div>
                </div>
            </div>
        </motion.div>}
    </AnimatePresence>
}
export default Intro

