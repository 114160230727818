import {FC} from "react";
import {useSnapshot} from "valtio";
import Star from "./Star";
import {motion,AnimatePresence} from "framer-motion";
import {frontEndStore} from "../../store";
import Fixed from "../ui/Fixed";

const MiniMap:FC=()=>{
    const miniMapSnap = useSnapshot(frontEndStore.miniMapState)
    const {step} = miniMapSnap

    return <>
        <Fixed className="flex flex-col items-center justify-center md:justify-start">
            <div
                className="fixed top-0 left-0 -translate-y-8 md:translate-y-0 translate-x-[calc(50vw-37px)] md:translate-x-0 fixed relative pb-4 flex justify-center items-center md:items-start pointer-events-none"
            >
                <div className="absolute flex justify-center rotate-90 md:rotate-0">
                    <img className="object-cover max-w-[834px] scale-50"
                         src="./images/desktop/Desktop_Path_All.png" alt=""/>
                </div>
                <div className="absolute flex flex-col items-center rotate-90 md:rotate-0">
                    <div
                        className="justify-between items-center w-[808px] h-[74.77px] flex scale-50">
                        <Star isShow={step === 1}/>
                        <Star isShow={step === 2}/>
                        <Star isShow={step === 3}/>
                        <Star isShow={step === 4}/>
                        <Star isShow={step === 5}/>
                        <Star isShow={step === 6}/>
                        <Star isShow={step === 7}/>
                        <Star isShow={step === 8}/>
                    </div>
                </div>
            </div>
        </Fixed>

        {/*<div className="w-screen fixed bottom-2 z-10">*/}
        {/*    <div className="btn font-wt pointer-events-auto" onClick={()=>{*/}
        {/*        if(frontEndStore.miniMapState.step===8){*/}
        {/*            frontEndStore.miniMapState.step = 1*/}
        {/*        }else{*/}
        {/*            frontEndStore.miniMapState.step +=1*/}
        {/*        }*/}
        {/*    }}>切換場景</div>*/}
        {/*</div>*/}

    </>
}
export default MiniMap