import {motion} from "framer-motion";
import {FC} from "react";

function generateRandomNumber() {
    const randomNumber = Math.floor(Math.random() * 3);
    const result = (randomNumber+3).toFixed(1);
    return parseInt(result);
}

const Star:FC=()=>{
    const delatTimer = generateRandomNumber()

    return <div className="relative mb-4 flex items-center justify-center">
        <motion.div
            className="absolute bg-white w-2 h-1 rounded-full"
            initial={{
                // scaleX:1.5,
                opacity:1
            }}
            animate={{
                scaleX:[0,1.2,0],
                scaleY:[0,1.2,0],
                // opacity:[1,0,1]
            }}
            transition={{
                repeat:Infinity,
                duration:delatTimer,
            }}
            style={{
                rotateZ:10,
                skewX:45,
                skewY:45,
            }}
        > </motion.div>

        <motion.div
            className="absolute bg-white w-2 h-1 rounded-full"
            initial={{
                // scaleX:1.5,
                opacity:1
            }}
            animate={{
                scaleX:[0,1.2,0],
                scaleY:[0,1.2,0],
                // opacity:[1,0,1]
            }}
            transition={{
                repeat:Infinity,
                duration:delatTimer,
            }}
            style={{
                rotateZ:-10,
                skewX:-45,
                skewY:-45,
            }}
        > </motion.div>

        <motion.div
            className="absolute bg-white w-10 h-10"
            initial={{scaleY:1.2}}
            animate={{
                scaleY:[1.2,2,1.2]
            }}
            transition={{
                repeat:Infinity,
                duration:delatTimer/2,
            }}
            style={{
                rotateZ:45,
                rotateY:85,
            }}
        > </motion.div>

        <motion.div
            className="bg-white w-4 h-4"
            initial={{scaleX:2}}
            animate={{
                scaleX:[2,1.2,2]
            }}
            transition={{
                repeat:Infinity,
                duration:delatTimer/2,
            }}
            style={{
                rotateZ:45,
                rotateX:80,
            }}
        > </motion.div>

    </div>

}

export default Star