import React, {FC, useEffect, useState} from "react";
import {proxy, useSnapshot} from "valtio";
import {AnimatePresence, motion} from "framer-motion";
import InformationWindow from "./InformationWindow/InformationWindow";
import {frontEndStore, windowType} from "../store";

const HelpWindow:FC=()=>{
    const {isButtonOpen,type,isLock,data} = useSnapshot(frontEndStore.helpWindowState)
    const {lang} = useSnapshot(frontEndStore.informationState)

    const [playInit,setPlayInit]=useState(false)
    useEffect(()=>{
        let timer = setTimeout(()=>{})
        if(type===windowType.planetUi){
            setPlayInit(true)
        }
        if(type!==windowType.planetUi&&playInit){
            timer = setTimeout(()=>{
                setPlayInit(false)
            },200)
        }
        return ()=>{clearTimeout(timer)}
    },[type])

    useEffect(()=>{
        let timer = setTimeout(()=>{})
        if(isLock){
            timer = setTimeout(()=>{
                frontEndStore.helpWindowState.isLock = false
            },4000)
        }
        return ()=>{clearTimeout(timer)}
    },[isLock])


    return <div className="w-screen h-screen fixed top-0 left-0">
        {/*backdrop*/}
        {type===windowType.teach&&<div
            className={`absolute w-full h-full bg-[#333333] transition-opacity duration-700 ${isButtonOpen ? 'opacity-60 pointer-events-auto ':'opacity-0'}`}
            onClick={() => {
                frontEndStore.helpWindowState.isButtonOpen = false
            }}
        />}
        {type===windowType.info&&<div
            className={`absolute w-full h-full bg-[#333333] transition-opacity duration-700 ${isButtonOpen ? 'opacity-25 pointer-events-auto ':'opacity-0'}`}
            onClick={() => {
                frontEndStore.helpWindowState.isButtonOpen = false
            }}
        />}



        {/*操作說明*/}
        <div className={`absolute scale-90 w-full inset-0 flex justify-center items-center pointer-events-none transition-opacity duration-700 ${(type===windowType.teach)&&isButtonOpen?'opacity-100':'opacity-0'}`}>
            {/*主要內容*/}
            <div className="w-full flex flex-col justify-center items-center gap-8 text-white font-wt">
                {/*上花邊*/}
                <img className="w-11/12 md:w-2/3 md:min-w-[500px]" src="./images/desktop/Desktop_Start_Title_Frame_Up.png"/>

                <div className="text-center text-5xl">{data[lang].title}</div>

                <div className="relative rounded-xl w-2/3 md:w-1/2 md:max-w-[560px] h-1/2 py-12 px-28 -mt-6 -mb-6">
                    {/*花邊部分*/}
                    <div className="absolute inset-0 flex flex-col justify-center items-center">
                        <div className="flex w-full">
                            <div className="h-20"><motion.img className="h-full object-contain" style={{rotateY:180}} src="./images/frame/Desktop_page-91.png" alt=""/></div>
                            <div className="h-20 flex-1"><motion.img className="h-full w-full" src="./images/frame/Desktop_page-92.png" alt=""/></div>
                            <div className="h-20"><motion.img className="h-full object-contain" style={{rotateY:0}} src="./images/frame/Desktop_page-91.png" alt=""/></div>
                        </div>
                        <div className="flex w-full flex-1">
                            <motion.div className="w-20 h-full"><motion.img className="h-full" style={{rotateY:180}} src="./images/frame/Desktop_page-93.png" alt=""/></motion.div>
                            <div className="h-full flex-1"></div>
                            <motion.div className="w-20 h-full"><motion.img className="h-full" style={{rotateY:0}} src="./images/frame/Desktop_page-93.png" alt=""/></motion.div>
                        </div>
                        <div className="flex w-full">
                            <div className="h-20"><motion.img className="h-full object-contain" style={{rotateX:180,rotateY:180}} src="./images/frame/Desktop_page-91.png" alt=""/></div>
                            <div className="h-20 flex-1"><motion.img className="h-full w-full" style={{rotateX:180}} src="./images/frame/Desktop_page-92.png" alt=""/></div>
                            <div className="h-20"><motion.img className="h-full object-contain" style={{rotateX:180,rotateY:0}} src="./images/frame/Desktop_page-91.png" alt=""/></div>
                        </div>
                    </div>

                        {lang==='zh'&&<>
                            {frontEndStore.isMobile&&<img className="w-full object-contain" src="./images/desktop/Mobile_Operation_Finger.png"/>}
                            {!frontEndStore.isMobile&&<img className="w-full object-contain" src="./images/desktop/Desktop_Operation_Mouse.png"/>}
                        </>}
                        {lang==='en'&&<>
                            {frontEndStore.isMobile&&<img className="w-full object-contain" src="./images/desktop/Mobile_Operation_Finger_en.png"/>}
                            {!frontEndStore.isMobile&&<img className="w-full object-contain" src="./images/desktop/Desktop_Operation_Mouse_en.png"/>}
                        </>}

                </div>

                {/*下花邊*/}
                <img className="w-11/12 md:w-2/3 md:min-w-[500px]" src="./images/desktop/Desktop_Start_Title_Frame_Down.png"/>
            </div>
        </div>


        <InformationWindow/>


        <div className={`scale-75 origin-top-right lg:scale-100 absolute right-7 top-8 z-50  drop-shadow-[0_0_6px_rgba(0,0,0,0.8)] `}>
            {/*按鈕的圖案部分*/}
            <div className="absolute w-full h-full flex justify-center items-center pointer-events-none">
                {type!==windowType.planetUi&&<>
                    {/*i的上面*/}
                    <motion.div
                        className="absolute rounded-full bg-white z-20"
                        initial={{opacity: 0}}
                        animate={isButtonOpen ? {
                            width:6,height:6,translateY:-14,opacity: 0, transition: {delay: 0.3,translateY:{delay:1}}
                        } : {
                            width:6,height:6,translateY:-14,opacity: 1, transition: {delay: 0.5,translateY:{delay:1}}
                        }}
                    />
                    {/*i的下面*/}
                    <motion.div
                        className="absolute w-[6px] rounded-full bg-white z-20"
                        initial={{height: 0}}
                        animate={playInit?{height:26,translateY:4,transition:{delay:1}}:isButtonOpen ? {
                            opacity:1,
                            height: 36, translateY: 0, rotateZ: 45,
                            transition: {rotateZ: {delay: 0.5}}
                        } : {
                            opacity:1,
                            height: 26, translateY: 4, rotateZ: 0,
                            transition: {height: {delay: 0.5}, translateY: {delay: 0.5}}
                        }}
                    />
                    <motion.div
                        className="absolute w-[6px] h-[26px] rounded-full bg-white z-20"
                        initial={{height:0}}
                        animate={playInit?{height:26,translateY:4,transition:{delay:1}}:isButtonOpen ? {
                            opacity:1,
                            height: 36, translateY: 0, rotateZ: -45,
                            transition: {rotateZ: {delay: 0.5}}
                        } : {
                            opacity:1,
                            height: 26, translateY: 4, rotateZ: 0,
                            transition: {height: {delay: 0.5}, translateY: {delay: 0.5}}
                        }}
                    />
                </>}

                <AnimatePresence>
                    {type===windowType.planetUi&&<>
                        <motion.div
                            className="absolute w-[6px] h-[6px] rounded-full z-20"
                            initial={{opacity: 1,translateY:-14,width:6,height:6,backgroundColor:'white'}}
                            animate={{translateY:0,width:36,height:36,opacity:0}}
                            transition={{translateY:{delay:0},default:{delay:0.5},opacity:{delay:1}}}
                            exit={{opacity: 1,translateY:-14,width:6,height:6,backgroundColor:'white',transition:{translateY:{delay:1},default:{delay:0.5},opacity:{delay:0}}}}
                        >

                        </motion.div>
                        <motion.img
                            src="./images/desktop/Desktop_Planet_Btn.png"
                            className="absolute object-contain z-10"
                            style={{width:36,height:36}}
                            initial={{opacity:0}}
                            animate={{opacity:1}}
                            transition={{delay:1}}
                            exit={{opacity:0,transition:{delay:0}}}
                        />
                        {/*i的下面*/}
                        <motion.div
                            className="absolute rounded-full bg-white z-20"
                            initial={{width:6,height:26,translateY:4}}
                            animate={{width:6,height:0,translateY:0}}
                            exit={{width:6,height:26,translateY:4,transition:{delay:1}}}
                        />
                    </>}
                </AnimatePresence>
            </div>

            {/*按鈕的邊框部分*/}
            <div
                className={`group w-16 h-16 p-[2px] rotate-45 rounded-md bg-[#838480] hover:bg-[#b9b4ab] transition-all border border-white border-[5px] shadow-xl ${isLock?'pointer-events-none':'pointer-events-auto cursor-pointer'}`}
                onClick={()=>{
                    frontEndStore.helpWindowState.isButtonOpen = !isButtonOpen
                }}
            >
                <div className="w-full h-full cursor-pointer rounded-md bg-[#838480] group-hover:bg-[#b9b4ab] transition-all border border-white border-[2px]"/>
            </div>
        </div>
    </div>
}
export default HelpWindow