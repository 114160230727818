import setStateToDocument from "./util/setStateToDocument";
import infoData from "./components/InformationWindow/infoData";
import {proxy} from "valtio";
import {carouselState} from "./components/PlanetCarousel/PlanetCarousel";
import introData from "./components/Intro/introData";

export enum windowType { teach,info,planetUi }

const frontEndState = proxy({
    isShow:false,
    isMobile:false,
    oldDevice:false,
    intro:{
        isShow:true,
        isShowButton:false,
        data:introData,
    },
    miniMapState:{
        isShow:false,
        step: 1,
    },
    carouselState,
    helpWindowState:{
        isShow:false,
        type:windowType.teach,
        isButtonOpen:false,
        isLock:false,
        data:{
            zh:{title:"【操作提示】"},
            en:{title:"【Tips】"},
        }
    },
    informationState:{
        isShow:false,
        contentId:'',
        lang:'zh',
        contentData:infoData.data[0],
        buttonSrc:infoData.buttonSrc,
    },

    macrameState:{
        isShow:false
    },
    circleState:{
        isShow:false
    }
})

export const frontEndStore = setStateToDocument(frontEndState)