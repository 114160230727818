import {FC} from "react";
import {motion} from "framer-motion";
import Star from "./Star";
import {useSnapshot} from "valtio";
import {windowSizeState} from "../../util/windowSize";

const rotateProps=(time:number)=>{
    return {
        initial:{rotateZ:0},
        animate:{rotateZ:[0,360,0]},
        transition:{duration:time,repeat:Infinity,delay:0}
    }
}

const Circles:FC=()=>{
    const {lg,md} = useSnapshot(windowSizeState)

    return <motion.div
        className="absolute w-[800px] h-[800px] left-0 top-0 flex justify-center items-center pointer-events-none"
        style={lg
            ?{translateX:-541,translateY:-465,scale:1.3}
            :md
                ?{translateX:-541,translateY:-465,scale:1}
                :{translateX:-500,translateY:-465,scale:0.8}
        }
    >
        <motion.div className="fixed right-0 bottom-0"
                    style={{scale:1,translateX:'-200px',translateY:'-235px'}}
        >
            <Star/>
        </motion.div>
        <motion.div className="fixed right-0 bottom-0"
                    style={{scale:0.6,translateX:'-255px',translateY:'-150px'}}
        >
            <Star/>
        </motion.div>
        <motion.div className="fixed right-0 bottom-0"
                    style={{scale:1,translateX:'-235px',translateY:'50px'}}
        >
            <Star/>
        </motion.div>

        <motion.img className="absolute select-none  pointer-events-none" src="./images/desktop/circle/Desktop_Hall_PlanetRing_01.png"
                    initial={{rotateZ:0}}
                    animate={{rotateZ:-360}}
                    transition={{duration:40,repeat:Infinity}}
                    style={{translateX:82,translateY:154,width:'60%',height:'60%'}}
        />
        <motion.img className="absolute w-5/6 h-5/6  select-none pointer-events-none" src="./images/desktop/circle/Desktop_Hall_PlanetRing_02.png"
                    initial={{rotateZ:0}}
                    animate={{rotateZ:360}}
                    transition={{duration:30,repeat:Infinity}}
                    style={{translateX:35,translateY:70}}
        />
        <motion.img className="absolute w-full h-full  select-none pointer-events-none" src="./images/desktop/circle/Desktop_Hall_PlanetRing_03.png"
                    initial={{rotateZ:0}}
                    animate={{rotateZ:360}}
                    transition={{duration:50,repeat:Infinity}}
                    style={{translateX:0,translateY:0}}
        />
    </motion.div>
}
export default Circles